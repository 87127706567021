$(document).ready(function () {


  // media queries
  const mq = [
    window.matchMedia("(max-width: 767px)"),
    window.matchMedia("(min-width: 768px) and (max-width: 1279px)"),
    window.matchMedia("(min-width: 1280px)")
  ];


  // resize events
  let current;
  const
    toggleFilterUrl = ".toggle-filter-url-js",
    toggleFilterBox = ".toggle-filter-box-js";

  $(window).on("resize", function () {
    if (current !== "mobile" && mq[0].matches) {

      current = "mobile";

    } else if (current !== "tablet" && mq[1].matches) {

      // filter fix from mobile
      $(toggleFilterBox).css("display", "").removeClass("open").prev(toggleFilterUrl).removeClass("active");

      current = "tablet";

    } else if (current !== "desktop" && mq[2].matches) {

      // filter fix from mobile
      $(toggleFilterBox).css("display", "").removeClass("open").prev(toggleFilterUrl).removeClass("active");

      current = "desktop";

    }
  }).trigger("resize");


  // drop open and hide when click outside
  const
    links = ".dropdown-link-js",
    innerElements = ".dropdown-js";

  $(document).on("click", links, function (e) {
    if ($(this).hasClass("active")) {
      $(links).filter('a[href="' + this.hash + '"]').removeClass("active");
      $(innerElements).filter(this.hash).removeClass("open");
    } else {
      $(links).removeClass("active").filter('a[href="' + this.hash + '"]').addClass("active");
      $(innerElements).removeClass("open").filter(this.hash).addClass("open");
      $(links).add(innerElements).off();
    }
    e.preventDefault();
  }).on("mouseup touchstart keyup", function (e) {
    if ((!$(links).add(innerElements).is(e.target) && $(links).add(innerElements).has(e.target).length === 0) || e.keyCode === 27) {
      $(links).removeClass("active");
      $(innerElements).removeClass("open");
    }
  });


  // sub on tablet
  $(document).on("click touchstart", ".nav_lang__links, .nav_main__links--sub", function (e) {
    if (e.type === "touchstart" && !mq[0].matches) {
      $(this).off("click").on("click", function (e) {
        e.preventDefault();
      });
    }
  });


  // sub on mobile
  $(document).on("click", ".nav_main__links--sub", function (e) {
    if ($(this).next(".nav_main__drop").length) {
      $(this).toggleClass("mob-active").next(".nav_main__drop").slideToggle();
      e.preventDefault();
    }
  });


  // cat side options open on mobile
  $(document).on("click", toggleFilterUrl, function (e) {
    $(this).toggleClass("active").next(toggleFilterBox).slideToggle(function () {
      $(this).toggleClass("open");
      if ($(this).prev().hasClass("active")) {
        $("html, body").animate({
          scrollTop: $(this).prev().offset().top
        }, 1000);
      }
    });
    e.preventDefault();
  });


  // cat side options toggle
  $(document).on("click", ".toggle-url-js", function (e) {
    $(this).toggleClass("active").next(".toggle-box-js").slideToggle(function () {
      $(this).toggleClass("open");
      if (mq[0].matches && $(this).prev().hasClass("active")) {
        $("html, body").animate({
          scrollTop: $(this).prev().offset().top
        }, 1000);
      }
    });
    e.preventDefault();
  });


  // sliders
  if ($.fn.slick) {
    $(".home-slider-js").slick({
      arrows: false,
      dots: false,
      fade: true,
      autoplay: true
    });
    $(".product-slider-js").slick({
      dots: false,
      autoplay: false,
      infinite: true,
      slidesToShow: 4,
      slidesToScroll: 1,
      prevArrow: '<button type="button" class="slick-prev" aria-label="Previous"><i class="icon-chevron-left" aria-hidden="true"></i></button>',
      nextArrow: '<button type="button" class="slick-next" aria-label="Next"><i class="icon-chevron-right" aria-hidden="true"></i></button>',
      responsive: [{
        breakpoint: 1240,
        settings: {
          dots: true,
          arrows: false,
          slidesToShow: 3
        }
        }, {
        breakpoint: 900,
        settings: {
          dots: true,
          arrows: false,
          slidesToShow: 2
        }
        }, {
        breakpoint: 590,
        settings: {
          dots: true,
          arrows: false,
          slidesToShow: 1
        }
        }]
    });
    $(".gallery-js").slick({
      arrows: false,
      dots: false,
      fade: true,
      autoplay: true
    });
  }


  // custom select
  if ($.fn.selectric) {
    $("select").selectric({
      maxHeight: 200,
      nativeOnMobile: false,
      arrowButtonMarkup: '<i aria-hidden="true" class="icon-chevron-down"></i>',
      responsive: true
    });
  }


  // modal
  if ($.fn.magnificPopup) {
    let windowPos;

    $(".gallery-js .slick-slide:not(.slick-cloned)").magnificPopup({
      delegate: "a",
      type: "image",
      tClose: "",
      closeMarkup: '<button type="button" class="mfp-close">X</button>',
      tLoading: '<i aria-hidden="true" class="icon-spinner"></i>',
      removalDelay: 400,
      mainClass: "mfp-fade",
      gallery: {
        enabled: true,
        tPrev: "",
        tNext: "",
        tCounter: '<span class="mfp-counter">%curr% - %total%</span>'
      },
      callbacks: {
        beforeOpen: function () {
          windowPos = $(window).scrollTop();
          $("body").addClass("modal_open");
        },
        afterClose: function () {
          $("body").removeClass("modal_open");
          $(window).scrollTop(windowPos);
        },
        change: function () {
          $(".gallery-js").slick("slickGoTo", this.index);
        }
      }
    });
  }


  // google map
  if ($("#map-js").length) {
    const
      latlng = new google.maps.LatLng(54.6849319, 25.2647027),
      myOptions = {
        zoom: 15,
        disableDefaultUI: true,
        center: latlng
      },
      map = new google.maps.Map(document.getElementById("map-js"), myOptions);

    let marker;

    marker = new google.maps.Marker({
      position: latlng,
      map: map,
      icon: "img/map-dot.png"
    });
  }


  // table responsive
  $("table:not([class])").each(function () {
    while (this.attributes.length > 0) {
      this.removeAttribute(this.attributes[0].name);
    }
    $(this).wrapAll('<div class="table-responsive"></div>');
  });


});
